@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: transformY(-100%);
            transform: transformY(-100%);
  }

  100% {
    -webkit-transform: transformY(0%);
            transform: transformY(0%);
  }
}

@keyframes slide-down {
  0% {
    -webkit-transform: transformY(-100%);
            transform: transformY(-100%);
  }

  100% {
    -webkit-transform: transformY(0%);
            transform: transformY(-0%);
  }
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: transformY(0%);
            transform: transformY(0%);
  }

  100% {
    -webkit-transform: transformY(-100%);
            transform: transformY(-100%);
  }
}

@keyframes slide-up {
  0% {
    -webkit-transform: transformY(0%);
            transform: transformY(0%);
  }

  100% {
    -webkit-transform: transformY(-100%);
            transform: transformY(-100%);
  }
}